






export default {
  name: "DashboardWelcome",
};
